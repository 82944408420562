import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import MainLayout from './MainLayout'
import CenterOverlay from '../components/CenterOverlay/CenterOverlay'
import IntroLargeTitle from '../components/IntroLargeTitle/IntroLargeTitle'
import Wysiwyg from '../components/Wysiwyg/Wysiwyg'
import getLanguageCode from '../utils/getLanguageCode'

class DefaultLegalPageTemplate extends Component {
  componentDidMount () {
    const locale = this.props.pageContext.locale
    const translations = this.props.pageContext.translations
    const langData = { locale, translations }
    this.props.setLangData(langData)
    this.props.showHeroBorder(true)
  }

  render () {
    const metaTitle = this.props.data.page.acf.metaTitle
    const metaDescription = this.props.data.page.acf.metaDescription
    const pageTitle = this.props.data.page.title
    const content = this.props.data.page.content
    const locale = this.props.pageContext.locale
    const bookContent = this.props.data.bookContent
    this.props.data.page.mainHeader = this.props.data.mainHeader
    const languageCode = getLanguageCode(locale)

    return (
      <div>
        <Helmet htmlAttributes={{ lang: languageCode }}>
          <title>{metaTitle}</title>
          <meta name='description' content={metaDescription} />
          <link
            rel='canonical'
            href={`${process.env.GATSBY_SITE_URL}${this.props.data.page.path}`}
          />
          {this.props.pageContext.translations.map(
            ({ locale_name, path }, i) => {
              return (
                <link
                  key={i}
                  rel='alternate'
                  hreflang={locale_name.replace('_', '-')}
                  href={`${process.env.GATSBY_SITE_URL}${path}`}
                />
              )
            }
          )}
          {this.props.pageContext.translations.map(
            ({ native_name, path }, i) => {
              if (native_name === 'English') {
                return (
                  <link
                    key={i}
                    rel='alternate'
                    hreflang='x-default'
                    href={`${process.env.GATSBY_SITE_URL}${path}`}
                  />
                )
              }
            }
          )}
        </Helmet>
        <MainLayout
          mainHeaderData={this.props.data.mainHeader}
          bookContent={bookContent}
          locale={locale}
          locationKey={this.props.location.key || ''}
          translations={this.props.pageContext.translations}
        >
          <CenterOverlay noTopPadding={false}>
            <IntroLargeTitle text={pageTitle} />
            <Wysiwyg content={content} leftOverlay />
          </CenterOverlay>
        </MainLayout>
      </div>
    )
  }
}

DefaultLegalPageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = ({ showHeroBorder, setLangData }) => {
  return { showHeroBorder, setLangData }
}

const mapDispatchToProps = dispatch => {
  return {
    showHeroBorder: show =>
      dispatch({
        type: `SHOW_DESKTOP_HEADER_BORDER`,
        showBorder: show
      }),
    setLangData: langData =>
      dispatch({
        type: `LANG_DATA`,
        langData
      })
  }
}

const LegalPageTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLegalPageTemplate)

export default LegalPageTemplate

export const legalPageQuery = graphql`
  query($id: String!, $locale: String!) {
    page: wordpressPage(id: { eq: $id }) {
      path
      title
      content
      acf {
        metaTitle: meta_title
        metaDescription: meta_description
      }
    }
    bookContent: wordpressStickyBook(language_code: { eq: $locale }) {
      ...StickyBookContent
    }
    mainHeader: wordpressMainHeader(language_code: { eq: $locale }) {
      ...MainHeaderContent
    }
  }
`
